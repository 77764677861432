import { Typography } from "@equinor/eds-core-react";
import React from "react";
import Layout from "../components/Layout";
import PlantList from "../components/PlantList";

const DefaultLanding = () => (
  <div>
    <Typography variant="h1" color="primary">
      Z-015
    </Typography>
    <Typography variant="body_short">
      Equinors vedlegg til NORSOK Standard Z-015. For leverandører av
      midlertidig utstyr, det vil si utstyr som er beregnet for tidsbegrenset
      bruk på innretninger og som krever oppkobling for bruk og/eller utgjør en
      mulig tennkilde.
    </Typography>
    <br />
    <Typography variant="h6">
      Dataark for oppkopling mot offshore innretning
    </Typography>
    <Typography variant="body_short">
      Ved å velge innretning og se på dataarkene på denne siden vises det
      hvordan oppkoplingen mot den enkelte innretning skal være.
      <br />
      Innretning velges ved å trykke på ikonet øverst til høyre eller ved å
      velge fra listen under.
    </Typography>
    <br />
    <Typography variant="h6">Sjekklister for midlertidig utstyr</Typography>
    <Typography variant="body_short">
      Norsok sjekklister for midlertidig utstyr er tilgjengelig i World- og
      pdf-format på norsok side for Z-015:{" "}
      <Typography
        link
        href="https://standard.no/en/sectors/energi-og-klima/petroleum/norsok-standard-categories/z-temporary-equipm/"
      >
        Link til standard.no
      </Typography>
    </Typography>
    <Typography variant="body_short">
      Leverandøren velger relevant liste og fyller denne ut. Forhåndsgodkjenning
      fra ansvarlig person i Equinor skal innhentes og påføres sjekklisten i de
      tilfeller som er nevnt i Z-015 avsnitt 5.1.3. Utstyret skal være sjekket
      og listen signert av ansvarlig for Løfteteknisk/mekanisk og
      Elektrofagmann. Sjekklisten skal ligge som vedlegg til pakkseddel.
    </Typography>
  </div>
);

const Landing = ({ landingPartialHtml }) => {
  if (landingPartialHtml) {
    return <div dangerouslySetInnerHTML={{ __html: landingPartialHtml }} />;
  }

  return <DefaultLanding />;
};

const indexPage = ({ pageContext }) => {
  const { plantNavList, landingPartialHtml } = pageContext;

  return (
    <Layout plantList={plantNavList}>
      <Landing landingPartialHtml={landingPartialHtml} />
      <br />
      <br />
      <br />
      <Typography variant="h4">Velg innretning</Typography>
      <br />
      <PlantList plantList={plantNavList} />
    </Layout>
  );
};

export default indexPage;
